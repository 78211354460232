import React from "react"

import classes from "./footer.module.css"

const Footer = ({ email, legal, impressum }) => {
  return (
    <footer className={classes.footerClass}>
      -· &copy; {new Date().getUTCFullYear()} compose.us GmbH · Contact us via{" "}
      &nbsp;
      <a href={"mailto:" + email}>{email}</a>
      &nbsp;·&nbsp;
      <a href={legal} target="_blank">{impressum}</a>
      &nbsp; ·-
    </footer>
  )
}

export default Footer
