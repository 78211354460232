import React from "react"

import Layout from "../components/layout/layout"

import classes from "../styles/main.module.css"

import heroImage from "../../content/assets/hero.svg"
import secondImage from "../../content/assets/second.svg"
import thirdImage from "../../content/assets/third.svg"
import ctaImage from "../../content/assets/cta.svg"

const IndexPage = () => {
  return (
    <Layout>
      <section className={classes.sectionClass}>
        <img src={heroImage} alt="hero" />
      </section>
      <section className={classes.sectionClass}>
        <img src={secondImage} alt="second" />
      </section>
      <section className={classes.sectionClass}>
        <img src={thirdImage} alt="third" />
      </section>
      <section className={classes.sectionClass}>
        <a href="mailto:servus@compose.us">
          <img src={ctaImage} alt="cta" />
        </a>
      </section>
    </Layout>
  )
}

export default IndexPage
