import React from "react"

import classes from "./header.module.css"

const Header = ({ email, brand, phone }) => {
  return (
    <nav className={classes.header}>
      <div className={classes.headerContent}>
        <p className={classes.collapsable}>
          <a href={"mailto:" + email}>
            <span className={[classes.icon, classes.iconEmail].join(" ")} />
            {email}
          </a>
        </p>
      </div>
      <div className={classes.headerContent}>
        <span className={classes.branding}>{brand}</span>
      </div>
      <div className={classes.headerContent}>
        <p className={classes.collapsable}>
          <a href={"tel:" + phone}>
            <span className={[classes.icon, classes.iconPhone].join(" ")} />
            {phone}
          </a>
        </p>
      </div>
    </nav>
  )
}

export default Header
