import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../header/header"
import Footer from "../footer/footer"

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            email
            phone
          }
        }
      }
    `
  )
  const { title, email, phone } = data.site.siteMetadata
  return (
    <Fragment>
      <Header brand={title} email={email} phone={phone} />
      <main>{children}</main>
      <Footer
        email={email}
        legal={"https://compose.us/impressum/"}
        impressum={"Impressum"}
      />
    </Fragment>
  )
}
